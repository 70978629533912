$(() => {
  let prefecturesLoaded = false;
  const selectPrefectureModal = $('.select_prefecture_modal').dialog({
    autoOpen: false,
    height: 'auto',
    width: 'auto',
    modal: true,
    position: {
      my: 'left top',
      at: 'left bottom+5',
      of: $('.js-select-prefecture'),
    },
    classes: {
      'ui-dialog': 'm_select_prefecture_dialog',
    },
    // ダイアログの周辺を暗くしない
    open() {
      $('.ui-widget-overlay').addClass('background_none');
      if (!prefecturesLoaded) {
        $.ajax({
          url: '/prefectures',
          dataType: 'script',
        });
        prefecturesLoaded = true;
      }
    },
    close() {
      $('.ui-widget-overlay').removeClass('background_none');
    },
  });

  // 地域選択dialogを表示
  $('.js-select-prefecture').click(e => {
    e.preventDefault();
    if (selectPrefectureModal.dialog('isOpen')) {
      selectPrefectureModal.dialog('close');
    } else {
      selectPrefectureModal.dialog('open');
    }
  });
});
